
















































import { Component, Vue } from "vue-property-decorator";
import gql from "graphql-tag";
import zip from "lodash/zip";
import { formatDate } from "@/utils";

/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/camelcase  */

// Baue Nested Sort auf:
// https://hasura.io/docs/1.0/graphql/core/queries/sorting.html#sorting-based-on-nested-object-s-fields
const createHasuraOrderClause = (sortBy: string, sortDesc: boolean) => {
  const order: any = {};
  const sortVariant = sortDesc ? "desc" : "asc";
  const sortBySplit = sortBy.split(".") ?? [];

  // Nicht das schönste, aber garantiert korrekt ist besser als "clever" und schwer zu debuggen.
  // Dreifaches Nesten tritt bei "arbeitsplatz.maschine.bezeichnung" auf.
  switch (sortBySplit.length) {
    case 1:
      // Bearbeiter ist Sonderfall,
      // weil Feldwert aus Vor- und Nachname
      // zusammengesetzt ist.
      order[sortBySplit[0]] =
        sortBySplit[0] === "bearbeiter"
          ? {
              vorname: sortVariant,
              nachname: sortVariant,
            }
          : sortVariant;
      break;
    case 2:
      order[sortBySplit[0]] = { [sortBySplit[1]]: sortVariant };
      break;
    case 3:
      order[sortBySplit[0]] = {
        [sortBySplit[1]]: { [sortBySplit[2]]: sortVariant },
      };
      break;
    default:
      break;
  }

  return order;
};

@Component({
  apollo: {
    count: {
      query: gql`
        query BetriebsdatenCount {
          betriebsdaten_aggregate {
            aggregate {
              count
            }
          }
        }
      `,
      pollInterval: 10000,
      update: (data) => data?.betriebsdaten_aggregate?.aggregate?.count ?? 0,
    },
    items: {
      query: gql`
        query BDEQuery(
          $limit: Int = 10
          $offset: Int = 0
          $order: [betriebsdaten_order_by!] = { buchung_id: desc }
          $auftrag_id: String
        ) {
          betriebsdaten(
            limit: $limit
            offset: $offset
            order_by: $order
            where: { auftrag_id: { _like: $auftrag_id } }
          ) {
            buchung_id
            bearbeiter {
              bearbeiter_id
              vorname
              nachname
            }
            arbeitsplatz {
              bezeichnung
              maschine {
                bezeichnung
              }
            }
            auftrag_id
            auftrag_volumen_verarbeitet
            auftrag_volumen
            bemerkung
            datum_start
            datum_ende
            erstellt
            prozess {
              bezeichnung
            }
            vorgang {
              bezeichnung
            }
            attribut_werts {
              attribut {
                bezeichnung
              }
              wert
            }
            auftrag {
              nummer
              position
              kundensw
            }
          }
        }
      `,
      update: (data) =>
        data?.betriebsdaten?.map((d: Record<string, any>) => ({
          ...d,
          datum_start: formatDate(new Date(`${d?.datum_start}Z`)),
          datum_ende: formatDate(new Date(`${d?.datum_ende}Z`)),
          erstellt: new Date(`${d?.erstellt}Z`).toLocaleString("de-DE"),
          bearbeiter: `${d?.bearbeiter?.vorname} ${d?.bearbeiter?.nachname}`,
          userId: d?.bearbeiter?.bearbeiter_id,
        })),
      variables() {
        return {
          ...this.apolloOption,
        };
      },
      pollInterval: 10000,
      // siehe https://www.apollographql.com/docs/react/data/queries/#cache-and-network
      fetchPolicy: "cache-and-network",
    },
  },
})
export default class DatenTabelle extends Vue {
  count = 0;
  headers = [
    { text: "Nummer", value: "auftrag.nummer" },
    { text: "Position", value: "auftrag.position" },
    { text: "Kunde", value: "auftrag.kundensw" },
    { text: "Maschine", value: "arbeitsplatz.maschine.bezeichnung" },
    { text: "Arbeitsplatz", value: "arbeitsplatz.bezeichnung" },
    { text: "Prozess", value: "prozess.bezeichnung" },
    { text: "Vorgang", value: "vorgang.bezeichnung" },
    { text: "Bearbeiter", value: "bearbeiter" },
    { text: "Vol. verarbeitet", value: "auftrag_volumen_verarbeitet" },
    { text: "Auftragsvolumen", value: "auftrag_volumen" },
    { text: "Bemerkung", value: "bemerkung" },
    { text: "Anfang", value: "datum_start" },
    { text: "Ende", value: "datum_ende" },
    { text: "Erstellt", value: "erstellt" },
    { text: "Bearbeiten", value: "update", sortable: false },
    { text: "", value: "data-table-expand" },
  ];
  items = [];

  options = {
    itemsPerPage: 10,
    page: 1,
    sortBy: [] as string[],
    sortDesc: [] as boolean[],
  };
  auftragsFilter = "";

  get apolloOption() {
    const options = this.options;
    const { itemsPerPage, page, sortBy, sortDesc } = options;

    const order = zip(sortBy, sortDesc).map(([sB, sD]) =>
      sB !== undefined && sD !== undefined
        ? createHasuraOrderClause(sB, sD)
        : {}
    );

    return {
      // -1 ist vuetifys Darstellung für "Alle" Rows der Table
      limit: itemsPerPage === -1 ? 200 : itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      // Sorgt dafür dass der Default Filter wirkt, und nicht
      // mit einem leeren Array außer Kraft gesetzt wird.
      // Wir möchte das ungefiltert die neusten Einträge zuerst
      // kommen, was wir in der Query mittels
      // `$order: [betriebsdaten_order_by!] = { buchung_id: desc }`
      // sicherstellen.
      order: order.length === 0 ? undefined : order,
      auftrag_id: this.auftragsFilter ? `${this.auftragsFilter}%` : null,
    };
  }
}
