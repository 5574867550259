






import { Component, Vue } from "vue-property-decorator";
import DatenTabelle from "@/components/DatenTabelle.vue"; // @ is an alias to /src

@Component({
  components: {
    DatenTabelle,
  },
})
export default class Betriebsdaten extends Vue {}
